import Vue from "vue";
import Component from "vue-class-component";
import config from "@/common/config/config";
import { Prop } from "@/common/decorators/Prop";

@Component
export default class FooterComponent extends Vue {
  @Prop({ required: true }) versie!: string;

  get digitaalVlaanderenLink(): string {
    return config.footer.links.digitaalVlaanderen;
  }

  get overLink(): string {
    return config.footer.links.over;
  }

  get disclaimerLink(): string {
    return config.footer.links.disclaimer;
  }

  get toegankelijkheidLink(): string {
    return config.footer.links.toegankelijkheid;
  }

  get faqLink(): string {
    return config.footer.links.faq;
  }
}
